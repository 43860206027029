import React from "react";

const AskQues = () => {
  return (
    <>
      <section className="py-3 mt-3">
        <div className="container">
          <div className="row gy-5 gy-lg-0 align-items-lg-center">
            <div className="text-center mb-5  ASKQUES">
              <h2 className="display-5 fw-bold main_change">
                Frequently{" "}
                <strong
                  className="fw-bold"
                  style={{
                    color: "#25AD0F",
                  }}
                >
                  Asked Question
                </strong>
              </h2>
            </div>
            <div className="container faqs main_ask_ques">
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div className="accordion-item custom-accordion ">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button
                      className="accordion-button collapsed custom-accordion-btn"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      How does EL TUTOR work?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body custom-accordion-body">
                      {/* Placeholder content for this accordion, which is intended
                      to demonstrate the <code>.accordion-flush</code> class.
                      This is the first item's accordion body. */}
                      EL TUTOR is a platform that connects students with
                      qualified tutors for personalized home tuition. Students
                      can search for tutors based on their needs, while tutors
                      can create profiles and offer their services.
                    </div>
                  </div>
                </div>
                <div className="accordion-item custom-accordion">
                  <h2 className="accordion-header" id="flush-headingTwo">
                    <button
                      className="accordion-button collapsed custom-accordion-btn"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      How do I find the right tutor for my needs?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body custom-accordion-body">
                      You can use EL TUTOR's search filters to find tutors based
                      on their qualifications, experience, subject expertise,
                      and availability. You can also read reviews and ratings
                      from other students.
                    </div>
                  </div>
                </div>
                <div className="accordion-item custom-accordion">
                  <h2 className="accordion-header" id="flush-headingThree">
                    <button
                      className="accordion-button collapsed custom-accordion-btn"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      How do I schedule a tutoring session?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body custom-accordion-body">
                      Once you've found a tutor, you can directly contact them
                      to discuss your requirements and schedule a session. The
                      platform might provide tools for scheduling and booking
                      sessions.
                    </div>
                  </div>
                </div>
                <div className="accordion-item custom-accordion">
                  <h2 className="accordion-header" id="flush-headingFour">
                    <button
                      className="accordion-button collapsed custom-accordion-btn"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFour"
                      aria-expanded="false"
                      aria-controls="flush-collapseFour"
                    >
                      Is there a fee to use EL Tutor?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingFour"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body custom-accordion-body">
                      Signing up for EL Tutor is free. However, each tutor sets
                      their own rates based on their qualifications and
                      experience, which will be transparently provided before
                      booking a session.
                    </div>
                  </div>
                </div>
                <div className="accordion-item custom-accordion">
                  <h2 className="accordion-header" id="flush-headingFive">
                    <button
                      className="accordion-button collapsed custom-accordion-btn"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFive"
                      aria-expanded="false"
                      aria-controls="flush-collapseFive"
                    >
                      What if I encounter technical difficulties while using the
                      platform?{" "}
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFive"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingFive"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body custom-accordion-body">
                      EL TUTOR should provide customer support channels, such as
                      a help center or contact form, to assist you with any
                      technical issues.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AskQues;
